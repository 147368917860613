/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Space+Mono:ital@0;1&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@0;1&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:wght@500&display=swap");

.song {
  font-family: "Roboto Mono", monospace;
  /* font-family: 'Spline Sans Mono', monospace; */
  white-space: pre-wrap;
  line-height: 0.7;
  .chords {
    font-weight: bold !important;
    /* color: #c70039; */
    color: var(--ion-color-danger);
  }
}

.live {
  width: 95vw;
  color: var(--ion-color-dark);
  overflow: hidden;
  .title-live {
    color: var(--ion-color-tertiary);
    text-align: center;
    font-size: min(30px, 3.2vw);
    font-weight: bold !important;
  }
  #song-live {
    font-family: "Roboto Mono", monospace;
    font-weight: bold !important;
    white-space: pre;
    line-height: 1em;
    font-size: min(30px, 3.2vw);
    .chords {
      color: var(--ion-color-danger);
    }
  }
}

#contentSong {
  ion-card-header {
    padding-bottom: 0;
  }
}

.title {
  font-weight: bold !important;
  color: var(--ion-color-tertiary);
}

.fullscreen {
  --width: 100%;
  --height: 100%;
  ion-list {
    max-width: 1024px;
  }
  div {
    max-width: 1024px;
  }
}

.reunions {
  --width: 100%;
  --height: 100%;
}

.bd-opacity {
  --backdrop-opacity: 0.5 !important;
}

.svgChords ion-slides {
  display: -webkit-inline-box;
  flex-wrap: wrap;
  width: 100px;
}

.svg-chord svg {
  height: 150px;
}

ion-datetime {
  color: var(--ion-color-dark);
}

.compartir {
  visibility: hidden;
}

iframe {
  max-width: 100%;
}

.songCard {
  ion-card {
    margin-left: 0;
    margin-right: 0;
  }
}

.scripture {
  p {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    color: #333;
  }
  p:nth-child(even) {
    background-color: #f4f4f4; // fondo gris claro para líneas pares
  }
  p:nth-child(odd) {
    background-color: #f9f9f9; // fondo blanco para líneas impares
  }
}
